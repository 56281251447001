// uses the CSS var '--color'
export const Logo = () =>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 viewBox="0 0 820.54 198.42" style={{fill: 'var(--color)'}}>

<path fill="transparent" d="M59.36,198.42c-19.79,0-39.57,0-59.36,0C0,132.28,0,66.14,0,0c273.51,0,547.03,0,820.54,0
	c0,52.72,0,105.43,0,158.15c-0.16-0.87-0.31-1.74-0.5-2.6c-1.73-8.17-6.21-14.08-14.21-17c-5.19-1.9-10.54-1.85-15.86-0.86
	c-7.35,1.37-13.11,5.12-16.5,11.97c-2.42,4.88-2.99,10.07-2.19,15.41c0.65,4.37,2.25,8.36,5.24,11.71
	c4.83,5.41,11.17,7.47,18.14,7.92c7.85,0.51,15-1.73,21.52-6.04c1.07-0.7,2.04-1.55,3.14-2.39c-1.12-1.41-2.12-2.67-3.14-3.95
	c-0.43,0.29-0.77,0.5-1.1,0.73c-4.63,3.3-9.62,5.71-15.39,6.18c-5.17,0.43-10.16-0.24-14.69-2.89c-5.31-3.1-7.67-7.98-8.14-14.05
	c14.64,0,29.16,0,43.69,0c0,12.04,0,24.08,0,36.12c-252.67,0-505.34,0-758,0c0.18-0.07,0.36-0.18,0.55-0.22
	c2.3-0.48,4.65-0.77,6.88-1.46c10.65-3.31,18.66-10.07,24.46-19.42c7.81-12.59,15.42-25.31,23.11-37.97
	c0.16-0.26,0.26-0.55,0.45-0.94c-2.06,0-3.97,0.05-5.87-0.02c-0.9-0.04-1.36,0.31-1.8,1.06c-5.9,10-11.84,19.97-17.77,29.95
	c-0.2,0.33-0.43,0.64-0.7,1.05c-0.67-0.87-1.26-1.64-1.85-2.41c-7.27-9.59-14.53-19.18-21.8-28.76c-0.25-0.33-0.62-0.82-0.95-0.83
	c-2.3-0.07-4.59-0.04-7.13-0.04c0.4,0.56,0.64,0.92,0.9,1.26c8.91,11.7,17.82,23.42,26.77,35.09c0.7,0.91,0.64,1.49-0.09,2.25
	c-2.07,2.13-3.98,4.42-6.18,6.4c-4.78,4.31-10.27,7.39-16.69,8.55c-1.7,0.31-3.44,0.38-5.48,0.59
	C59.36,194.52,59.36,196.47,59.36,198.42z M69.95,21.14c-0.47-1.79-0.84-3.4-1.32-4.97c-2.6-8.34-8.05-13.61-16.76-15.15
	c-7.35-1.3-14.52-0.4-21.4,2.48c-7.53,3.14-13.54,8.32-18.83,14.4c-0.73,0.84-1.46,1.67-2.44,2.78c0-6.39,0-12.39,0-18.43
	c-2.87,0-5.61,0-8.36,0c0,27.47,0,54.86,0,82.37c2.28,0,4.47,0,6.66,0c1.72,0,1.72,0,1.72-1.69c0-17.41-0.01-34.82,0.03-52.23
	c0-0.7,0.27-1.54,0.71-2.07c2.24-2.69,4.45-5.41,6.89-7.91c5.33-5.44,11.39-9.81,18.8-12.05c3.86-1.16,7.81-1.74,11.81-1.27
	c5.84,0.68,9.46,4.37,11.87,9.4c2.49,5.17,3.1,10.77,3.12,16.4c0.08,16.59,0.04,33.19,0.04,49.79c0,0.51,0,1.02,0,1.63
	c2.39,0,4.59-0.08,6.77,0.03c1.29,0.07,1.53-0.38,1.53-1.58c-0.04-17.45-0.03-34.9,0-52.35c0-0.7,0.27-1.55,0.71-2.07
	c2.34-2.76,4.67-5.54,7.2-8.12c4.71-4.81,10.04-8.77,16.42-11.15c4.49-1.67,9.13-2.4,13.9-2.03c6.28,0.49,10.2,4.18,12.59,9.75
	c2.29,5.33,2.87,10.99,2.89,16.7c0.06,16.39,0.03,32.78,0.03,49.18c0,0.51,0,1.02,0,1.64c2.42,0,4.65-0.07,6.88,0.03
	c1.17,0.05,1.41-0.36,1.41-1.45c-0.03-16.68,0-33.35-0.03-50.03c-0.01-5.83-0.47-11.61-2.36-17.19
	c-2.43-7.19-6.93-12.34-14.44-14.45c-4.54-1.27-9.18-1.3-13.81-0.72C90.47,2.27,80.92,7.74,73.39,16.79
	C72.25,18.15,71.18,19.58,69.95,21.14z M604.53,84.61c0-27.57,0-54.97,0-82.35c-2.7,0-5.32,0-8.06,0c0,23.9,0,47.71,0,71.74
	c-1.04-0.75-1.91-1.32-2.74-1.97c-17.03-13.44-30.85-29.71-42.05-48.19c-4.03-6.66-7.38-13.74-11.07-20.61c-0.22-0.41-0.65-1-1-1.01
	c-2.27-0.09-4.54-0.05-6.85-0.05c0,27.53,0,54.95,0,82.36c2.66,0,5.21,0,7.86,0c0-21.13,0-42.17,0-63.51
	c0.33,0.53,0.5,0.79,0.65,1.06c9.51,17.41,21.56,32.89,35.76,46.71c5.78,5.63,11.99,10.76,18.81,15.1c0.47,0.3,1.01,0.68,1.52,0.69
	C599.73,84.65,602.08,84.61,604.53,84.61z M650.7,21.16c0.32,0.38,0.41,0.46,0.47,0.56c5.94,11.37,13.53,21.62,21.6,31.54
	c8.8,10.82,18.78,20.38,30.06,28.61c2.71,1.98,5.46,3.23,8.87,2.76c0.98-0.14,1.99-0.02,2.97-0.02c0-27.6,0-54.96,0-82.38
	c-2.69,0-5.31,0-8.07,0c0,23.89,0,47.69,0,71.56c-0.26-0.07-0.39-0.07-0.48-0.13c-1.31-0.96-2.67-1.87-3.92-2.9
	c-17.07-14.07-31.11-30.72-42.14-49.86c-3.32-5.77-6.22-11.78-9.32-17.67c-0.21-0.4-0.56-1.01-0.87-1.02
	c-2.34-0.09-4.69-0.05-7.05-0.05c0,27.54,0,54.93,0,82.45c2.11,0,4.14,0,6.16,0c1.73,0,1.73,0,1.73-1.68c0-20.01,0-40.02,0-60.03
	C650.7,22.43,650.7,21.95,650.7,21.16z M319.98,49.03c0.46-0.1,0.72-0.17,1-0.22c6.2-0.95,11.61-3.53,16.3-7.7
	c12.29-10.93,8.47-29.43-5.07-35.65c-5.09-2.34-10.43-3.3-15.99-3.3c-12.29,0-24.59,0-36.88,0c-0.43,0-0.85,0.04-1.24,0.06
	c0,27.53,0,54.92,0,82.31c2.77,0,5.43,0,8.24,0c0-11.61,0-23.14,0-35.02c8.19,0,16.25-0.01,24.3,0.03c0.31,0,0.74,0.32,0.91,0.61
	c4.93,8.4,10.98,15.94,17.83,22.83c4.18,4.21,8.67,8.11,13.05,12.12c0.3,0.27,0.93,0.53,1.23,0.4c2.31-1.05,4.57-2.19,6.98-3.37
	c-6.09-4.68-11.63-9.69-16.79-15.13C328.65,61.52,323.87,55.73,319.98,49.03z M817.25,84.62c0-2.43,0-4.68,0-7.04
	c-18.65,0-37.19,0-55.75,0c0-10.13,0-20.15,0-30.3c14.47,0,28.85,0,43.25,0c0-2.4,0-4.65,0-7.05c-14.46,0-28.85,0-43.24,0
	c0-10.4,0-20.66,0-31.02c18.64,0,37.19,0,55.75,0c0-2.4,0-4.69,0-6.98c-21.28,0-42.48,0-63.81,0c0,0.55,0,1.02,0,1.5
	c0,26.43,0,52.87,0,79.3c0,1.59,0,1.59,1.58,1.59c20.27,0,40.54,0,60.81,0C816.29,84.62,816.73,84.62,817.25,84.62z M250.07,84.61
	c-0.19-0.49-0.27-0.75-0.39-1c-12.53-26.86-25.06-53.72-37.57-80.59c-0.3-0.65-0.66-0.88-1.37-0.87c-2.6,0.04-5.21,0.06-7.81-0.01
	c-0.98-0.02-1.49,0.27-1.92,1.19c-12.5,26.65-25.04,53.29-37.56,79.92c-0.18,0.39-0.33,0.79-0.57,1.37c2.64,0,5.11-0.04,7.58,0.02
	c0.95,0.03,1.43-0.25,1.82-1.15c2.72-6.12,5.52-12.21,8.25-18.33c0.43-0.98,0.92-1.38,2.06-1.37c15.92,0.05,31.83,0.04,47.75,0.01
	c1.08,0,1.64,0.27,2.1,1.31c2.72,6.17,5.52,12.3,8.33,18.42c0.19,0.42,0.63,1.02,0.98,1.03C244.44,84.65,247.16,84.61,250.07,84.61z
	 M504.37,84.61c-0.17-0.46-0.27-0.76-0.4-1.04c-12.46-26.85-24.93-53.7-37.38-80.55c-0.3-0.66-0.67-0.88-1.38-0.87
	c-2.69,0.04-5.37,0.06-8.06-0.01c-0.97-0.03-1.42,0.3-1.82,1.16c-12.38,26.62-24.79,53.22-37.19,79.83c-0.2,0.43-0.38,0.87-0.66,1.5
	c2.58,0,4.96-0.04,7.35,0.02c0.91,0.02,1.36-0.27,1.73-1.11c2.8-6.18,5.68-12.32,8.47-18.49c0.41-0.91,0.88-1.25,1.9-1.24
	c16.12,0.03,32.24,0.03,48.36,0c1.02,0,1.48,0.34,1.89,1.25c2.76,6.2,5.57,12.36,8.39,18.53c0.18,0.39,0.57,0.97,0.88,0.98
	C499.05,84.64,501.64,84.61,504.37,84.61z M181.34,167.16c7.8-10.33,15.47-20.5,23.14-30.67c0.09,0.03,0.18,0.05,0.26,0.08
	c0,15.7,0,31.4,0,47.16c2.16,0,4.17,0,6.23,0c0-19.12,0-38.17,0-57.33c-1.69,0-3.31,0.05-4.93-0.02c-0.82-0.03-1.34,0.25-1.82,0.9
	c-5.74,7.58-11.5,15.15-17.26,22.72c-1.85,2.44-3.71,4.87-5.62,7.38c-0.47-0.59-0.85-1.07-1.22-1.55
	c-7.29-9.52-14.58-19.04-21.88-28.54c-0.27-0.36-0.71-0.82-1.09-0.83c-1.86-0.09-3.72-0.04-5.63-0.04c0,19.18,0,38.22,0,57.31
	c2.08,0,4.08,0,6.25,0c0-15.78,0-31.48,0-47.17c0.08-0.02,0.16-0.04,0.24-0.06C165.76,146.66,173.49,156.84,181.34,167.16z
	 M626.33,145.46c-0.48-0.33-0.81-0.55-1.13-0.77c-8.17-5.53-17.08-8.34-26.96-6.54c-14.08,2.57-21.57,14.43-18.93,28.42
	c2.08,10.99,10.22,17.39,21.04,18.12c8.37,0.56,16-1.67,22.65-6.91c1.19-0.94,2.32-1.95,3.59-3.03c0.28,3.1,0.54,6.04,0.8,8.99
	c1.77,0,3.42,0,5.15,0c0-19.12,0-38.16,0-57.26c-2.08,0-4.09,0-6.21,0C626.33,132.8,626.33,139.04,626.33,145.46z M6.32,176.65
	c2.7,1.57,5.2,3.28,7.89,4.55c6.81,3.2,13.97,4.55,21.43,2.93c16.16-3.51,22.2-19.5,17.18-32.78c-3.34-8.83-10.53-12.86-19.54-13.61
	c-9.95-0.82-18.51,2.57-25.55,9.69c-0.29,0.3-0.49,0.7-0.73,1.05c-0.13-0.1-0.27-0.2-0.4-0.3c0-7.21,0-14.43,0-21.7
	c-2.08,0-4.05,0-6.06,0c0,19.12,0,38.17,0,57.25c1.72,0,3.36,0,5.07,0C5.86,181.28,6.1,178.91,6.32,176.65z M695.69,183.74
	c0-0.6,0-1.08,0-1.56c0-7,0.06-13.99-0.03-20.99c-0.04-2.59-0.25-5.2-0.72-7.74c-0.88-4.76-2.99-8.91-6.95-11.92
	c-3.19-2.43-6.86-3.58-10.83-3.93c-8.49-0.74-16.36,0.84-23.11,6.32c-2.15,1.75-4.1,3.76-6.26,5.77c1.79,1.11,3.29,2.04,4.75,2.95
	c0.22-0.19,0.32-0.27,0.39-0.36c6.32-7.37,14.37-10.55,23.97-9.49c5.11,0.56,9.12,3.08,11.33,7.92c1.12,2.44,0.72,3.66-1.8,4.71
	c-2.04,0.85-4.2,1.55-6.37,1.94c-5.71,1.02-11.46,1.78-17.17,2.75c-3.61,0.61-7.13,1.57-10.27,3.58c-4.31,2.76-5.72,6.82-5,11.68
	c0.64,4.32,3.33,7.02,7.39,8.39c3.64,1.23,7.38,1.35,11.19,1.02c7.42-0.65,14.02-3.28,19.77-7.99c1.39-1.13,2.67-2.41,4.31-3.9
	c0,3.84,0,7.31,0,10.85C692.2,183.74,693.85,183.74,695.69,183.74z M230.9,152.61c0.19-0.09,0.29-0.11,0.33-0.17
	c0.19-0.21,0.36-0.44,0.55-0.65c6.76-7.43,15.13-10.46,25.02-8.72c4.65,0.82,8.16,3.51,10.02,8.02c0.84,2.04,0.53,3.16-1.46,4.08
	c-1.86,0.85-3.84,1.6-5.84,1.96c-6.06,1.1-12.17,1.95-18.24,2.99c-3.57,0.61-7.05,1.57-10.16,3.53c-4.23,2.67-5.71,6.65-5.11,11.42
	c0.56,4.48,3.28,7.3,7.49,8.71c3.6,1.21,7.3,1.33,11.07,1c9.09-0.8,16.76-4.48,23.11-11c0.2-0.21,0.44-0.39,0.66-0.58
	c0.13,0.03,0.26,0.06,0.39,0.09c0,3.47,0,6.95,0,10.43c1.96,0,3.6,0,5.34,0c0-0.48,0-0.88,0-1.28c0-7.28,0.06-14.56-0.03-21.84
	c-0.03-2.5-0.23-5.04-0.75-7.48c-1.62-7.68-6.08-12.89-13.85-14.88c-4.27-1.1-8.59-0.97-12.95-0.39
	c-8.39,1.13-14.68,5.68-20.11,11.96C227.96,150.78,229.46,151.71,230.9,152.61z M348.39,162.28c14.53,0,28.97,0,43.65,0
	c-0.16-2.08-0.16-4.08-0.49-6.01c-1.39-7.91-5.45-13.89-12.97-17.19c-5.57-2.44-11.4-2.47-17.23-1.38
	c-7.7,1.43-13.55,5.48-16.86,12.76c-1.82,4.01-2.33,8.24-2.01,12.6c0.41,5.61,2.27,10.62,6.3,14.65c4.77,4.78,10.8,6.6,17.33,7
	c7.95,0.48,15.16-1.79,21.72-6.22c0.99-0.67,1.91-1.45,2.89-2.21c-1.12-1.41-2.12-2.67-3.07-3.87c-2.07,1.26-3.99,2.57-6.03,3.64
	c-7.72,4.02-15.67,4.57-23.7,1.09C352.01,174.59,348.41,168.7,348.39,162.28z M508.7,137.15c-2.65,0.33-5.35,0.47-7.95,1.03
	c-7.87,1.69-13.74,5.92-16.48,13.75c-1.65,4.7-1.8,9.56-0.81,14.41c1.62,7.87,6.02,13.61,13.67,16.44c7.54,2.79,15.22,2.75,22.8,0.2
	c6.97-2.35,11.53-7.23,13.73-14.25c1.28-4.1,1.45-8.3,0.82-12.53c-0.9-6.03-3.68-10.96-8.82-14.39
	C520.54,138.38,514.77,137.34,508.7,137.15z M717.69,183.75c0-0.63,0-1.11,0-1.6c0-8.78,0.01-17.57-0.02-26.35
	c0-0.9,0.27-1.5,0.93-2.09c3.27-2.9,6.77-5.46,10.71-7.36c4.13-1.99,8.38-3.49,13.06-3.38c4,0.09,6.75,2.01,8.33,5.65
	c1.04,2.38,1.4,4.92,1.42,7.48c0.04,8.74,0.02,17.49,0.02,26.23c0,0.47,0,0.94,0,1.4c2.16,0,4.14,0,6.24,0c0-0.56,0-1.04,0-1.52
	c0-8.3,0.03-16.59-0.02-24.89c-0.01-2.11-0.11-4.23-0.4-6.31c-0.48-3.39-1.64-6.53-4.08-9.05c-3.97-4.1-9.05-4.74-14.32-4.2
	c-6.61,0.68-12.49,3.35-17.85,7.21c-1.37,0.99-2.67,2.08-4.15,3.23c-0.19-3.33-0.37-6.54-0.54-9.73c-1.95,0-3.68,0-5.51,0
	c0,15.13,0,30.19,0,45.29C713.58,183.75,715.56,183.75,717.69,183.75z M424.37,178.67c2.5,1.43,4.76,3.08,7.28,4.09
	c7.63,3.07,15.49,3.37,23.37,1.01c6.41-1.92,10.87-6.13,13.56-12.24c1.82-4.15,2.59-8.53,2.62-13.02
	c0.07-10.29,0.02-20.58,0.02-30.86c0-0.39,0-0.78,0-1.16c-13.02,0-25.86,0-38.72,0c0,1.75,0,3.43,0,5.28c10.85,0,21.61,0,32.39,0
	c0.03,0.45,0.06,0.73,0.06,1.01c0,8.05,0.07,16.1-0.04,24.15c-0.04,2.74-0.26,5.53-0.82,8.21c-1.3,6.32-4.76,11.08-11.17,12.94
	c-7.28,2.11-14.45,1.75-21.17-2.19c-1.18-0.69-2.32-1.46-3.58-2.25C426.94,175.25,425.76,176.83,424.37,178.67z M389.52,84.54
	c0-27.54,0-54.91,0-82.28c-2.71,0-5.32,0-7.91,0c0,27.48,0,54.86,0,82.28C384.29,84.54,386.87,84.54,389.52,84.54z M554.53,183.74
	c0-0.57,0-1.04,0-1.52c0-9.11-0.01-18.22,0.02-27.33c0-0.52,0.14-1.17,0.47-1.53c3.89-4.33,8.66-7.18,14.45-8.14
	c1.24-0.2,2.02-0.48,2.06-1.99c0.05-1.8,0.4-3.58,0.63-5.55c-7.04,1.3-12.59,4.85-17.65,9.63c-0.1-0.77-0.19-1.29-0.22-1.82
	c-0.11-2.03-0.17-4.06-0.31-6.09c-0.02-0.34-0.36-0.95-0.57-0.96c-1.65-0.08-3.3-0.04-4.96-0.04c0,15.21,0,30.24,0,45.34
	C550.46,183.74,552.4,183.74,554.53,183.74z M296.07,183.75c0-0.6,0-1.08,0-1.57c0-9.11-0.01-18.22,0.03-27.33
	c0-0.58,0.26-1.28,0.65-1.7c3.91-4.26,8.66-7.05,14.43-7.96c1.17-0.18,1.92-0.48,1.86-1.88c-0.04-0.92,0.25-1.84,0.36-2.77
	c0.11-0.9,0.18-1.8,0.28-2.85c-7,1.3-12.54,4.82-17.63,9.64c-0.09-0.85-0.18-1.48-0.21-2.11c-0.1-1.95-0.15-3.9-0.31-5.84
	c-0.03-0.35-0.45-0.93-0.71-0.94c-1.57-0.09-3.15-0.04-4.8-0.04c0,15.18,0,30.24,0,45.35C292.02,183.75,293.96,183.75,296.07,183.75
	z M328.71,183.75c0-15.18,0-30.24,0-45.29c-2.04,0-3.97,0-5.96,0c0,15.12,0,30.18,0,45.29C324.76,183.75,326.7,183.75,328.71,183.75
	z M329.66,133.69c0-2.41,0-4.81,0-7.2c-2.68,0-5.3,0-7.91,0c0,2.45,0,4.81,0,7.2C324.43,133.69,326.99,133.69,329.66,133.69z"/>
<path d="M820.54,162.3c-14.52,0-29.05,0-43.69,0c0.47,6.07,2.83,10.95,8.14,14.05c4.53,2.65,9.52,3.32,14.69,2.89
	c5.76-0.48,10.76-2.88,15.39-6.18c0.33-0.23,0.67-0.45,1.1-0.73c1.02,1.28,2.02,2.54,3.14,3.95c-1.1,0.84-2.07,1.68-3.14,2.39
	c-6.52,4.31-13.67,6.55-21.52,6.04c-6.97-0.45-13.31-2.51-18.14-7.92c-2.99-3.35-4.59-7.33-5.24-11.71
	c-0.8-5.34-0.22-10.53,2.19-15.41c3.39-6.85,9.15-10.6,16.5-11.97c5.33-1,10.68-1.04,15.86,0.86c8,2.92,12.48,8.83,14.21,17
	c0.18,0.86,0.33,1.73,0.5,2.6C820.54,159.53,820.54,160.91,820.54,162.3z M814.07,157.02c0.06-0.13,0.12-0.2,0.13-0.27
	c0.01-0.16,0.02-0.33-0.01-0.49c-1.21-5.73-4.04-10.24-9.69-12.51c-4.08-1.64-8.33-1.76-12.58-0.95
	c-7.12,1.36-12.05,5.36-14.26,12.4c-0.18,0.56-0.26,1.15-0.41,1.82C789.63,157.02,801.87,157.02,814.07,157.02z"/>
<path d="M59.36,198.42c0-1.95,0-3.89,0-5.86c2.04-0.21,3.78-0.28,5.48-0.59c6.42-1.17,11.9-4.24,16.69-8.55
	c2.2-1.98,4.11-4.27,6.18-6.4c0.73-0.75,0.79-1.33,0.09-2.25c-8.95-11.67-17.86-23.39-26.77-35.09c-0.26-0.34-0.5-0.7-0.9-1.26
	c2.53,0,4.83-0.03,7.13,0.04c0.33,0.01,0.69,0.49,0.95,0.83c7.27,9.58,14.54,19.17,21.8,28.76c0.58,0.77,1.18,1.54,1.85,2.41
	c0.27-0.41,0.5-0.72,0.7-1.05c5.93-9.98,11.87-19.96,17.77-29.95c0.44-0.75,0.89-1.1,1.8-1.06c1.9,0.07,3.81,0.02,5.87,0.02
	c-0.19,0.4-0.29,0.68-0.45,0.94c-7.69,12.67-15.29,25.38-23.11,37.97c-5.8,9.35-13.81,16.12-24.46,19.42
	c-2.23,0.69-4.59,0.98-6.88,1.46c-0.19,0.04-0.37,0.15-0.55,0.22C61.48,198.42,60.42,198.42,59.36,198.42z"/>
<path d="M69.95,21.14c1.23-1.56,2.3-2.98,3.44-4.35C80.92,7.74,90.47,2.27,102.2,0.8c4.63-0.58,9.27-0.55,13.81,0.72
	c7.51,2.1,12.01,7.26,14.44,14.45c1.89,5.58,2.35,11.37,2.36,17.19c0.03,16.68,0,33.35,0.03,50.03c0,1.09-0.24,1.5-1.41,1.45
	c-2.23-0.09-4.46-0.03-6.88-0.03c0-0.62,0-1.13,0-1.64c0-16.39,0.03-32.78-0.03-49.18c-0.02-5.71-0.6-11.37-2.89-16.7
	c-2.39-5.57-6.31-9.25-12.59-9.75c-4.77-0.37-9.41,0.36-13.9,2.03c-6.38,2.38-11.72,6.34-16.42,11.15c-2.53,2.58-4.86,5.36-7.2,8.12
	c-0.44,0.52-0.71,1.37-0.71,2.07c-0.04,17.45-0.04,34.9,0,52.35c0,1.2-0.24,1.65-1.53,1.58c-2.19-0.12-4.38-0.03-6.77-0.03
	c0-0.61,0-1.12,0-1.63c0-16.6,0.04-33.19-0.04-49.79c-0.03-5.63-0.64-11.22-3.12-16.4c-2.42-5.03-6.03-8.72-11.87-9.4
	c-4.01-0.47-7.96,0.11-11.81,1.27c-7.41,2.24-13.47,6.61-18.8,12.05c-2.44,2.49-4.66,5.22-6.89,7.91C9.52,29.16,9.25,30,9.24,30.7
	C9.21,48.11,9.21,65.52,9.21,82.93c0,1.69,0,1.69-1.72,1.69c-2.19,0-4.38,0-6.66,0c0-27.51,0-54.9,0-82.37c2.75,0,5.49,0,8.36,0
	c0,6.05,0,12.04,0,18.43c0.98-1.12,1.71-1.95,2.44-2.78c5.3-6.08,11.3-11.25,18.83-14.4c6.88-2.87,14.05-3.78,21.4-2.48
	c8.71,1.54,14.16,6.81,16.76,15.15C69.12,17.74,69.48,19.35,69.95,21.14z"/>
<path d="M604.53,84.61c-2.44,0-4.8,0.04-7.15-0.03c-0.52-0.02-1.05-0.39-1.52-0.69c-6.82-4.35-13.03-9.48-18.81-15.1
	c-14.2-13.82-26.25-29.3-35.76-46.71c-0.15-0.27-0.32-0.53-0.65-1.06c0,21.34,0,42.38,0,63.51c-2.65,0-5.19,0-7.86,0
	c0-27.41,0-54.83,0-82.36c2.3,0,4.58-0.04,6.85,0.05c0.35,0.01,0.78,0.6,1,1.01c3.69,6.87,7.03,13.95,11.07,20.61
	c11.2,18.48,25.02,34.75,42.05,48.19c0.82,0.65,1.7,1.23,2.74,1.97c0-24.03,0-47.83,0-71.74c2.74,0,5.35,0,8.06,0
	C604.53,29.64,604.53,57.04,604.53,84.61z"/>
<path d="M650.7,21.16c0,0.79,0,1.27,0,1.74c0,20.01,0,40.02,0,60.03c0,1.68,0,1.68-1.73,1.68c-2.03,0-4.05,0-6.16,0
	c0-27.52,0-54.9,0-82.45c2.36,0,4.71-0.04,7.05,0.05c0.31,0.01,0.66,0.62,0.87,1.02c3.11,5.89,6,11.91,9.32,17.67
	c11.03,19.15,25.07,35.79,42.14,49.86c1.25,1.03,2.61,1.94,3.92,2.9c0.09,0.06,0.22,0.06,0.48,0.13c0-23.87,0-47.67,0-71.56
	c2.76,0,5.38,0,8.07,0c0,27.42,0,54.78,0,82.38c-0.99,0-2-0.12-2.97,0.02c-3.41,0.48-6.16-0.78-8.87-2.76
	c-11.28-8.23-21.26-17.79-30.06-28.61c-8.07-9.93-15.65-20.17-21.6-31.54C651.11,21.62,651.01,21.54,650.7,21.16z"/>
<path d="M319.98,49.03c3.89,6.7,8.67,12.49,13.86,17.97c5.16,5.44,10.7,10.45,16.79,15.13c-2.41,1.18-4.67,2.32-6.98,3.37
	c-0.3,0.13-0.93-0.13-1.23-0.4c-4.38-4.01-8.87-7.91-13.05-12.12c-6.84-6.89-12.9-14.43-17.83-22.83c-0.17-0.29-0.6-0.61-0.91-0.61
	c-8.05-0.03-16.11-0.03-24.3-0.03c0,11.89,0,23.41,0,35.02c-2.8,0-5.47,0-8.24,0c0-27.4,0-54.78,0-82.31
	c0.39-0.02,0.82-0.06,1.24-0.06c12.29,0,24.59,0,36.88,0c5.56,0,10.9,0.96,15.99,3.3c13.54,6.22,17.36,24.72,5.07,35.65
	c-4.69,4.17-10.1,6.76-16.3,7.7C320.71,48.85,320.44,48.93,319.98,49.03z M286.34,42.91c0.25,0.04,0.37,0.08,0.48,0.08
	c10.05-0.01,20.11,0.08,30.15-0.1c4.76-0.08,9.19-1.62,13.03-4.56c10.01-7.66,8.37-21.84-3.12-26.83c-3.79-1.65-7.79-2.24-11.9-2.24
	c-9.16-0.01-18.31,0-27.47,0c-0.39,0-0.77,0-1.18,0C286.34,20.58,286.34,31.71,286.34,42.91z"/>
<path d="M817.25,84.62c-0.52,0-0.96,0-1.4,0c-20.27,0-40.54,0-60.81,0c-1.58,0-1.58,0-1.58-1.59c0-26.43,0-52.87,0-79.3
	c0-0.48,0-0.96,0-1.5c21.34,0,42.53,0,63.81,0c0,2.3,0,4.59,0,6.98c-18.56,0-37.11,0-55.75,0c0,10.36,0,20.62,0,31.02
	c14.4,0,28.78,0,43.24,0c0,2.4,0,4.66,0,7.05c-14.4,0-28.79,0-43.25,0c0,10.15,0,20.17,0,30.3c18.55,0,37.1,0,55.75,0
	C817.25,79.94,817.25,82.19,817.25,84.62z"/>
<path d="M250.07,84.61c-2.91,0-5.63,0.04-8.35-0.05c-0.34-0.01-0.78-0.61-0.98-1.03c-2.81-6.13-5.61-12.26-8.33-18.42
	c-0.46-1.04-1.02-1.31-2.1-1.31c-15.92,0.03-31.83,0.04-47.75-0.01c-1.14,0-1.62,0.4-2.06,1.37c-2.72,6.12-5.53,12.2-8.25,18.33
	c-0.4,0.9-0.88,1.18-1.82,1.15c-2.47-0.07-4.94-0.02-7.58-0.02c0.24-0.58,0.39-0.98,0.57-1.37c12.53-26.64,25.06-53.27,37.56-79.92
	c0.43-0.92,0.94-1.22,1.92-1.19c2.6,0.07,5.21,0.05,7.81,0.01c0.71-0.01,1.07,0.22,1.37,0.87c12.51,26.87,25.04,53.73,37.57,80.59
	C249.8,83.86,249.88,84.12,250.07,84.61z M228.31,56.5c-7.33-15.85-14.56-31.5-21.91-47.41c-7.43,15.95-14.72,31.62-22.07,47.41
	C199.06,56.5,213.56,56.5,228.31,56.5z"/>
<path d="M504.37,84.61c-2.73,0-5.32,0.03-7.91-0.04c-0.31-0.01-0.7-0.59-0.88-0.98c-2.82-6.17-5.63-12.34-8.39-18.53
	c-0.41-0.91-0.87-1.26-1.89-1.25c-16.12,0.03-32.24,0.03-48.36,0c-1.02,0-1.49,0.34-1.9,1.24c-2.8,6.18-5.67,12.32-8.47,18.49
	c-0.38,0.83-0.82,1.13-1.73,1.11c-2.38-0.06-4.77-0.02-7.35-0.02c0.28-0.63,0.46-1.07,0.66-1.5c12.4-26.61,24.81-53.21,37.19-79.83
	c0.4-0.86,0.85-1.18,1.82-1.16c2.68,0.08,5.37,0.05,8.06,0.01c0.71-0.01,1.07,0.22,1.38,0.87c12.45,26.86,24.92,53.7,37.38,80.55
	C504.1,83.85,504.2,84.15,504.37,84.61z M460.87,9.09c-7.3,15.91-14.49,31.6-21.73,47.38c14.71,0,29.23,0,43.96,0
	C475.68,40.65,468.33,24.98,460.87,9.09z"/>
<path d="M181.34,167.16c-7.85-10.32-15.58-20.49-23.32-30.67c-0.08,0.02-0.16,0.04-0.24,0.06c0,15.69,0,31.39,0,47.17
	c-2.17,0-4.17,0-6.25,0c0-19.08,0-38.13,0-57.31c1.91,0,3.77-0.05,5.63,0.04c0.38,0.02,0.81,0.48,1.09,0.83
	c7.3,9.51,14.59,19.03,21.88,28.54c0.37,0.48,0.75,0.95,1.22,1.55c1.91-2.5,3.77-4.94,5.62-7.38c5.76-7.57,11.52-15.13,17.26-22.72
	c0.49-0.64,1-0.93,1.82-0.9c1.62,0.07,3.24,0.02,4.93,0.02c0,19.16,0,38.21,0,57.33c-2.06,0-4.07,0-6.23,0c0-15.76,0-31.46,0-47.16
	c-0.09-0.03-0.18-0.05-0.26-0.08C196.8,146.66,189.13,156.82,181.34,167.16z"/>
<path d="M626.33,145.46c0-6.43,0-12.66,0-18.98c2.12,0,4.13,0,6.21,0c0,19.09,0,38.14,0,57.26c-1.73,0-3.38,0-5.15,0
	c-0.26-2.96-0.53-5.89-0.8-8.99c-1.27,1.07-2.4,2.09-3.59,3.03c-6.65,5.24-14.28,7.48-22.65,6.91
	c-10.83-0.73-18.97-7.12-21.04-18.12c-2.64-13.99,4.85-25.85,18.93-28.42c9.88-1.8,18.79,1,26.96,6.54
	C625.52,144.91,625.84,145.13,626.33,145.46z M626.34,160.18c0-1.71-0.2-3.44,0.05-5.11c0.4-2.72-0.8-4.22-2.99-5.64
	c-7.34-4.77-15.16-7.4-23.97-5.89c-5.94,1.02-10.4,4.2-12.82,9.86c-1.5,3.52-1.79,7.22-1.3,10.98c0.64,4.91,2.58,9.16,6.86,11.94
	c5.26,3.43,11.07,3.65,17,2.43c6.64-1.36,11.98-5.03,16.68-9.73c0.29-0.29,0.46-0.85,0.47-1.28
	C626.36,165.22,626.34,162.7,626.34,160.18z"/>
<path d="M6.32,176.65c-0.22,2.26-0.46,4.64-0.7,7.08c-1.71,0-3.35,0-5.07,0c0-19.09,0-38.13,0-57.25c2.01,0,3.97,0,6.06,0
	c0,7.27,0,14.48,0,21.7c0.13,0.1,0.27,0.2,0.4,0.3c0.24-0.35,0.43-0.75,0.73-1.05c7.04-7.12,15.6-10.52,25.55-9.69
	c9.01,0.74,16.2,4.77,19.54,13.61c5.02,13.28-1.02,29.28-17.18,32.78c-7.47,1.62-14.62,0.28-21.43-2.93
	C11.52,179.92,9.02,178.22,6.32,176.65z M6.63,162.79c0,1.42,0.17,2.87-0.04,4.26c-0.4,2.68,0.73,4.24,2.95,5.68
	c7.47,4.89,15.48,7.5,24.45,6.12c5.67-0.87,9.91-3.95,12.37-9.2c1.89-4.02,2.21-8.3,1.5-12.62c-0.91-5.5-3.68-9.77-8.86-12.16
	c-4.92-2.27-10.05-2.44-15.22-1.01c-6.67,1.85-11.89,6.03-16.7,10.78c-0.29,0.29-0.4,0.86-0.41,1.31
	C6.61,158.24,6.63,160.52,6.63,162.79z"/>
<path d="M695.69,183.74c-1.84,0-3.49,0-5.4,0c0-3.54,0-7.01,0-10.85c-1.64,1.49-2.92,2.76-4.31,3.9c-5.76,4.7-12.35,7.34-19.77,7.99
	c-3.81,0.33-7.55,0.22-11.19-1.02c-4.06-1.37-6.74-4.07-7.39-8.39c-0.72-4.86,0.69-8.91,5-11.68c3.14-2.01,6.65-2.98,10.27-3.58
	c5.72-0.96,11.47-1.73,17.17-2.75c2.17-0.39,4.33-1.09,6.37-1.94c2.52-1.05,2.92-2.27,1.8-4.71c-2.22-4.83-6.22-7.36-11.33-7.92
	c-9.61-1.06-17.65,2.11-23.97,9.49c-0.08,0.09-0.18,0.16-0.39,0.36c-1.47-0.91-2.96-1.84-4.75-2.95c2.17-2.01,4.11-4.02,6.26-5.77
	c6.75-5.49,14.62-7.06,23.11-6.32c3.97,0.34,7.64,1.49,10.83,3.93c3.95,3.01,6.07,7.16,6.95,11.92c0.47,2.54,0.69,5.16,0.72,7.74
	c0.1,6.99,0.03,13.99,0.03,20.99C695.69,182.66,695.69,183.14,695.69,183.74z M690.06,159.63c-2.5,0.83-4.86,1.9-7.33,2.37
	c-5.6,1.07-11.27,1.81-16.9,2.74c-3.2,0.53-6.37,1.23-9.18,2.96c-2.36,1.45-3.59,3.54-3.15,6.34c0.41,2.66,2.32,3.96,4.76,4.61
	c3.08,0.82,6.19,0.7,9.31,0.26c6.02-0.84,11.53-2.96,16.32-6.74C687.91,168.98,690.61,165.04,690.06,159.63z"/>
<path d="M230.9,152.61c-1.44-0.9-2.95-1.83-4.51-2.81c5.43-6.27,11.72-10.83,20.11-11.96c4.36-0.58,8.68-0.71,12.95,0.39
	c7.77,2,12.22,7.21,13.85,14.88c0.52,2.44,0.72,4.98,0.75,7.48c0.1,7.28,0.03,14.56,0.03,21.84c0,0.4,0,0.8,0,1.28
	c-1.74,0-3.38,0-5.34,0c0-3.49,0-6.96,0-10.43c-0.13-0.03-0.26-0.06-0.39-0.09c-0.22,0.19-0.46,0.37-0.66,0.58
	c-6.34,6.52-14.02,10.2-23.11,11c-3.77,0.33-7.47,0.21-11.07-1c-4.21-1.41-6.93-4.23-7.49-8.71c-0.6-4.77,0.87-8.74,5.11-11.42
	c3.11-1.96,6.59-2.92,10.16-3.53c6.07-1.04,12.18-1.89,18.24-2.99c2-0.36,3.98-1.11,5.84-1.96c1.99-0.91,2.3-2.04,1.46-4.08
	c-1.86-4.51-5.37-7.2-10.02-8.02c-9.88-1.73-18.26,1.29-25.02,8.72c-0.19,0.21-0.36,0.44-0.55,0.65
	C231.19,152.5,231.1,152.52,230.9,152.61z M268.49,159.64c-2.52,0.83-4.88,1.9-7.36,2.37c-5.61,1.07-11.28,1.81-16.92,2.74
	c-3.2,0.53-6.38,1.22-9.18,3c-2.19,1.39-3.47,3.33-3.15,6.01c0.34,2.79,2.22,4.21,4.77,4.89c3.12,0.84,6.28,0.72,9.43,0.26
	c6.53-0.96,12.45-3.33,17.43-7.74C266.96,168.1,268.97,164.36,268.49,159.64z"/>
<path d="M348.39,162.28c0.02,6.43,3.62,12.32,9.51,14.86c8.04,3.48,15.99,2.93,23.7-1.09c2.05-1.07,3.96-2.38,6.03-3.64
	c0.95,1.2,1.95,2.46,3.07,3.87c-0.98,0.76-1.9,1.54-2.89,2.21c-6.56,4.43-13.77,6.7-21.72,6.22c-6.53-0.4-12.56-2.23-17.33-7
	c-4.03-4.03-5.89-9.04-6.3-14.65c-0.32-4.36,0.19-8.6,2.01-12.6c3.31-7.28,9.17-11.32,16.86-12.76c5.83-1.09,11.66-1.06,17.23,1.38
	c7.52,3.3,11.58,9.28,12.97,17.19c0.34,1.93,0.33,3.93,0.49,6.01C377.36,162.28,362.92,162.28,348.39,162.28z M348.66,156.99
	c12.38,0,24.68,0,37.06,0c-0.1-0.49-0.16-0.88-0.25-1.27c-1.48-6.33-5.03-10.82-11.48-12.61c-3.5-0.97-7.05-0.89-10.63-0.34
	C355.85,143.94,350.02,149.52,348.66,156.99z"/>
<path d="M508.7,137.15c6.07,0.19,11.84,1.24,16.96,4.66c5.14,3.43,7.92,8.36,8.82,14.39c0.63,4.23,0.47,8.43-0.82,12.53
	c-2.2,7.02-6.76,11.9-13.73,14.25c-7.58,2.55-15.27,2.59-22.8-0.2c-7.65-2.83-12.05-8.57-13.67-16.44c-1-4.85-0.85-9.71,0.81-14.41
	c2.75-7.82,8.62-12.06,16.48-13.75C503.36,137.62,506.05,137.48,508.7,137.15z M528.57,160.89c-0.15-1.41-0.18-2.84-0.46-4.23
	c-1.23-6.25-4.61-10.77-10.85-12.73c-5.79-1.83-11.63-1.72-17.32,0.39c-5.12,1.9-8.46,5.59-9.98,10.88
	c-0.88,3.06-0.92,6.16-0.44,9.28c0.93,6.01,4.03,10.46,9.67,12.94c4.55,2,9.34,2.37,14.21,1.64c4.64-0.7,8.71-2.59,11.59-6.48
	C527.57,169.12,528.47,165.14,528.57,160.89z"/>
<path d="M717.69,183.75c-2.13,0-4.11,0-6.18,0c0-15.1,0-30.16,0-45.29c1.82,0,3.56,0,5.51,0c0.18,3.19,0.36,6.4,0.54,9.73
	c1.48-1.15,2.78-2.24,4.15-3.23c5.36-3.86,11.25-6.53,17.85-7.21c5.27-0.54,10.35,0.1,14.32,4.2c2.44,2.52,3.6,5.67,4.08,9.05
	c0.3,2.08,0.39,4.21,0.4,6.31c0.04,8.3,0.02,16.59,0.02,24.89c0,0.48,0,0.96,0,1.52c-2.1,0-4.08,0-6.24,0c0-0.46,0-0.93,0-1.4
	c0-8.74,0.02-17.49-0.02-26.23c-0.01-2.56-0.38-5.09-1.42-7.48c-1.58-3.64-4.34-5.55-8.33-5.65c-4.68-0.11-8.93,1.39-13.06,3.38
	c-3.95,1.91-7.45,4.46-10.71,7.36c-0.66,0.59-0.93,1.19-0.93,2.09c0.04,8.78,0.02,17.57,0.02,26.35
	C717.69,182.64,717.69,183.12,717.69,183.75z"/>
<path d="M424.37,178.67c1.39-1.85,2.58-3.42,3.8-5.05c1.25,0.79,2.39,1.56,3.58,2.25c6.72,3.94,13.89,4.3,21.17,2.19
	c6.41-1.85,9.86-6.62,11.17-12.94c0.55-2.68,0.78-5.47,0.82-8.21c0.1-8.05,0.04-16.1,0.04-24.15c0-0.28-0.03-0.56-0.06-1.01
	c-10.78,0-21.53,0-32.39,0c0-1.84,0-3.52,0-5.28c12.86,0,25.7,0,38.72,0c0,0.39,0,0.77,0,1.16c0,10.29,0.04,20.58-0.02,30.86
	c-0.03,4.49-0.79,8.87-2.62,13.02c-2.68,6.12-7.15,10.32-13.56,12.24c-7.88,2.36-15.74,2.06-23.37-1.01
	C429.13,181.75,426.86,180.1,424.37,178.67z"/>
<path d="M389.52,84.54c-2.65,0-5.23,0-7.91,0c0-27.42,0-54.8,0-82.28c2.58,0,5.2,0,7.91,0C389.52,29.63,389.52,57.01,389.52,84.54z"
	/>
<path d="M554.53,183.74c-2.13,0-4.07,0-6.07,0c0-15.1,0-30.13,0-45.34c1.66,0,3.31-0.04,4.96,0.04c0.21,0.01,0.55,0.61,0.57,0.96
	c0.14,2.03,0.2,4.06,0.31,6.09c0.03,0.52,0.12,1.04,0.22,1.82c5.06-4.78,10.61-8.33,17.65-9.63c-0.24,1.96-0.59,3.75-0.63,5.55
	c-0.04,1.5-0.82,1.78-2.06,1.99c-5.8,0.96-10.56,3.81-14.45,8.14c-0.33,0.36-0.47,1.01-0.47,1.53c-0.03,9.11-0.02,18.22-0.02,27.33
	C554.53,182.7,554.53,183.17,554.53,183.74z"/>
<path d="M296.07,183.75c-2.11,0-4.05,0-6.06,0c0-15.12,0-30.18,0-45.35c1.65,0,3.23-0.05,4.8,0.04c0.27,0.02,0.69,0.6,0.71,0.94
	c0.16,1.94,0.2,3.89,0.31,5.84c0.03,0.63,0.12,1.26,0.21,2.11c5.08-4.82,10.62-8.34,17.63-9.64c-0.1,1.05-0.17,1.95-0.28,2.85
	c-0.11,0.93-0.4,1.85-0.36,2.77c0.06,1.41-0.69,1.7-1.86,1.88c-5.76,0.9-10.52,3.7-14.43,7.96c-0.39,0.42-0.65,1.13-0.65,1.7
	c-0.04,9.11-0.03,18.22-0.03,27.33C296.07,182.67,296.07,183.15,296.07,183.75z"/>
<path d="M328.71,183.75c-2.01,0-3.95,0-5.96,0c0-15.11,0-30.17,0-45.29c1.99,0,3.93,0,5.96,0
	C328.71,153.52,328.71,168.57,328.71,183.75z"/>
<path d="M329.66,133.69c-2.67,0-5.23,0-7.91,0c0-2.4,0-4.76,0-7.2c2.6,0,5.23,0,7.91,0C329.66,128.88,329.66,131.28,329.66,133.69z"
	/>
<path fill="transparent" d="M814.07,157.02c-12.2,0-24.44,0-36.83,0c0.15-0.67,0.23-1.26,0.41-1.82c2.22-7.04,7.15-11.04,14.26-12.4
	c4.25-0.81,8.5-0.69,12.58,0.95c5.66,2.27,8.49,6.77,9.69,12.51c0.03,0.16,0.03,0.32,0.01,0.49
	C814.19,156.82,814.13,156.89,814.07,157.02z"/>
<path fill="transparent" d="M286.34,42.91c0-11.21,0-22.34,0-33.65c0.4,0,0.79,0,1.18,0c9.16,0,18.31-0.01,27.47,0
	c4.1,0,8.11,0.6,11.9,2.24c11.49,4.99,13.13,19.17,3.12,26.83c-3.84,2.94-8.27,4.47-13.03,4.56c-10.05,0.18-20.1,0.08-30.15,0.1
	C286.71,42.99,286.59,42.95,286.34,42.91z"/>
<path fill="transparent" d="M228.31,56.5c-14.74,0-29.25,0-43.99,0c7.35-15.79,14.65-31.46,22.07-47.41
	C213.75,25.01,220.98,40.65,228.31,56.5z"/>
<path fill="transparent" d="M460.87,9.09c7.45,15.89,14.8,31.56,22.23,47.38c-14.73,0-29.25,0-43.96,0
	C446.38,40.69,453.58,25.01,460.87,9.09z"/>
<path fill="transparent" d="M626.34,160.18c0,2.52,0.02,5.04-0.02,7.56c-0.01,0.44-0.17,0.99-0.47,1.28c-4.7,4.71-10.04,8.37-16.68,9.73
	c-5.94,1.22-11.75,0.99-17-2.43c-4.28-2.79-6.22-7.03-6.86-11.94c-0.49-3.76-0.2-7.47,1.3-10.98c2.41-5.67,6.87-8.84,12.82-9.86
	c8.81-1.51,16.63,1.12,23.97,5.89c2.19,1.42,3.39,2.92,2.99,5.64C626.14,156.73,626.34,158.47,626.34,160.18z"/>
<path fill="transparent" d="M6.63,162.79c0-2.28-0.02-4.56,0.02-6.83c0.01-0.44,0.12-1.02,0.41-1.31c4.82-4.75,10.03-8.92,16.7-10.78
	c5.17-1.44,10.3-1.26,15.22,1.01c5.19,2.39,7.96,6.66,8.86,12.16c0.72,4.32,0.39,8.6-1.5,12.62c-2.46,5.25-6.7,8.33-12.37,9.2
	c-8.97,1.38-16.97-1.23-24.45-6.12c-2.21-1.45-3.35-3-2.95-5.68C6.8,165.67,6.63,164.22,6.63,162.79z"/>
<path fill="transparent" d="M690.06,159.63c0.55,5.41-2.15,9.35-6.19,12.54c-4.78,3.78-10.3,5.9-16.32,6.74c-3.11,0.44-6.23,0.56-9.31-0.26
	c-2.43-0.65-4.34-1.95-4.76-4.61c-0.43-2.79,0.8-4.88,3.15-6.34c2.82-1.74,5.98-2.43,9.18-2.96c5.63-0.93,11.3-1.68,16.9-2.74
	C685.21,161.53,687.57,160.46,690.06,159.63z"/>
<path fill="transparent" d="M268.49,159.64c0.48,4.72-1.53,8.46-4.98,11.52c-4.98,4.41-10.9,6.79-17.43,7.74
	c-3.15,0.46-6.32,0.58-9.43-0.26c-2.54-0.68-4.43-2.1-4.77-4.89c-0.32-2.68,0.96-4.62,3.15-6.01c2.8-1.77,5.98-2.46,9.18-3
	c5.64-0.94,11.31-1.68,16.92-2.74C263.61,161.53,265.97,160.47,268.49,159.64z"/>
<path fill="transparent" d="M348.66,156.99c1.36-7.47,7.19-13.05,14.71-14.22c3.57-0.56,7.13-0.64,10.63,0.34
	c6.44,1.8,9.99,6.28,11.48,12.61c0.09,0.39,0.15,0.78,0.25,1.27C373.34,156.99,361.04,156.99,348.66,156.99z"/>
<path fill="transparent" d="M528.57,160.89c-0.1,4.24-1.01,8.23-3.57,11.69c-2.88,3.89-6.95,5.77-11.59,6.48
	c-4.86,0.74-9.66,0.36-14.21-1.64c-5.64-2.48-8.74-6.93-9.67-12.94c-0.48-3.12-0.44-6.22,0.44-9.28c1.52-5.29,4.86-8.98,9.98-10.88
	c5.69-2.11,11.53-2.22,17.32-0.39c6.23,1.96,9.61,6.48,10.85,12.73C528.39,158.05,528.43,159.48,528.57,160.89z"/>
</svg>