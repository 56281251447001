import { api, isProd } from "./common"
import * as fbq from '../lib/fpixel'
import posthog from 'posthog-js'

export const track = infos => (dispatch, getState) => {
  isProd || console.log("TRACK", infos)
  return api("track", infos, { alertOnError: false })
}

export const getCart = () => (dispatch, getState) => {
  return api("cart")
    .then(({ cart }) => dispatch({ type: "SET_CART", payload: { cart } }))
}

export const getProductAvailability = id => (dispatch, getState) => {
  return api("product_availability", { 'product-id': id }, {alertOnError: false})
}

export const addToCart = ({ product, size, availability, context, price }) => (dispatch, getState) => {
  const { _id, name, model, nickname, composhort, price, pricepro, pricebraderie, pics, url, namegender } = product // info needed to display cart
  // BEWARE fields are filtered server-side too
  const productClean = { _id, name, model, nickname, composhort, price, pricepro, pricebraderie, pics, url, namegender }
  fbq.addToCart({ id: _id, name })
  posthog.capture('add_to_cart', {
    model, size, price, sku: _id,
  })
  const item = {
    product: productClean,
    price,
    size,
    availability,
    context,
  }
  return api("add_to_cart", { item }, {})
    .then((resp) => { // fields: cart, action, text (opt.)
      if (resp.cart) {
        dispatch({ type: "SET_CART", payload: { cart: resp.cart } })
      }
      return Promise.resolve(resp)
    })
}

export const removeFromCart = id => (dispatch, getState) => {
  return api("remove_from_cart", { id }, {})
    .then(({ cart }) => dispatch({ type: "SET_CART", payload: { cart } }))
}

export const submitCode = (code) => (dispatch, getState) => {
  return api("submit_code", { code })
    .then(r => r.cart ?
      dispatch({ type: "SET_CART", payload: r }) : r) 
}
export const removeCode = (code) => (dispatch, getState) => {
  return api("remove_code", { code })
    .then(r => r.cart ?
      dispatch({ type: "SET_CART", payload: r }) : r)
}
export const startCheckout = () => (dispatch, getState) => {
  fbq.initiateCheckout()
  posthog.capture('start_checkout')
  return track({event: 'start_checkout'})(dispatch, getState)
}
export const setShipping = shipping => (dispatch, getState) => {
  posthog.capture('complete_shipping')
  return api("set_shipping", { shipping }, {})
    .then(({ cart }) => dispatch({ type: "SET_SHIPPING", payload: { cart } }))
}
export const startPayment = ({ processor, facility, amount }) => (dispatch, getState) => {
  posthog.capture('start_payment')
  return api("start_payment", { processor, facility, amount })
}
export const submitGsc = ({ name, email }) => (dispatch, getState) => {
  posthog.capture('newsletter')
  return api("submit_gsc", { name, email })
}

export const setError = error => (dispatch, getState) => {
  return dispatch({ type: "SET_ERROR", payload: error })
}

// for testing
export const justDelay = (code) => (dispatch, getState) => {
  const myPromise = new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('foo');
    }, 2000);
  });
  return myPromise
}


// // INITIALIZES CLOCK ON SERVER
// export const serverRenderClock = () => (dispatch) =>
//   dispatch({
//     type: "TICK",
//     payload: { light: false, ts: Date.now() },
//   })

// // INITIALIZES CLOCK ON CLIENT
// export const startClock = () => (dispatch) =>
//   setInterval(() => {
//     dispatch({ type: "TICK", payload: { light: true, ts: Date.now() } })
//   }, 1000)

// // INCREMENT COUNTER BY 1
// export const incrementCount = () => ({ type: "INCREMENT" })

// // DECREMENT COUNTER BY 1
// export const decrementCount = () => ({ type: "DECREMENT" })

// // RESET COUNTER
// export const resetCount = () => ({ type: "RESET" })