import { SuperButton } from "./SuperButton";

// IMPORTANT
// to change height, use '--height' on style, NOT 'height' or CSS class!
export const PrimaryButton = ({ children, className, style, ...delegated }) => {
    const cn = `naoko uppercase w-full tracking-[0.2em] text-sm font-[500] ${className}`
    const st = { "--height": "3.5rem", ...style }
    return (
        <SuperButton
            className={cn}
            style={st}
            {...delegated}>{children}</SuperButton>
    )
}

// IMPORTANT
// to change height, use '--height' on style, NOT 'height' or CSS class!
export const SecondaryButton = ({ children, className, style, ...delegated }) => {
    const cn = `naoko uppercase w-full tracking-[0.2em] text-sm font-[500] ${className}`
    const st = {
        "--bg-color": "white", 
        "--color": "black",
        "--loading-bg-color": "rgba(0, 0, 0, 0.05)",
        "--border": "1px solid rgb(107 114 128)", 
        "--height": "3rem",
        ...style 
    }
    return (
        <SuperButton
            className={cn}
            style={st}
            {...delegated}>{children}</SuperButton>
    )
}