export const GOOGLE_ANALYTICS = "UA-159629060-1"

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    if (typeof gtag !== 'function') return
    window.gtag('config', GOOGLE_ANALYTICS, {
        page_path: url,
    })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    if (typeof gtag !== 'function') return
    window.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
    })
}