export const affilaeTrackingStript = `
    var _ae = {
    "pid":"631706140bc8447200079775",
    };
    (function() {
    var element = document.createElement('script'); element.type = 'text/javascript'; element.async = true;
    element.src = '//static.affilae.com/ae-v3.5.js';
    var scr = document.getElementsByTagName('script')[0]; scr.parentNode.insertBefore(element, scr);
    })();
`

export const affilaeDeclareConversion = ({orderId, amountHorsTaxe}: {orderId: string, amountHorsTaxe: number}) => {
    if (typeof window === "undefined") return
    const aeEvent = {
        key: '63170645aeb2b672a3bcb12e-631706140bc8447200079775',
        Conversion: {
            id: orderId,
            amount: amountHorsTaxe,
            currency: "EUR"
        }
    }
    if ('AeTracker' in window) {
        window['AeTracker'].sendConversion(aeEvent)
    } else {
        (window['AE'] = window['AE'] || []).push(aeEvent)
    }
}