import { useDispatch, useSelector } from "react-redux"

export const Banner = ({ banner, fullscreen }) => {
    const dispatch = useDispatch()
    const mmo = useSelector(state => state.system.mmo)

    if (typeof banner.text === "undefined" || banner.text === "") {
        return null // don't render anything
    }
    const st = mmo
        ? { height: 0, padding: 0 } // for transition
        : {}
    return (
        <div
            className={`bg-black text-white p-4 naoko tracking-widest transition-all
            ${fullscreen ? "hidden lg:block" : ""}`}
            style={st}
            dangerouslySetInnerHTML={{ __html: banner.text }}
        />)
}

export const ErrorBanner = () => {
    const error = useSelector(state => state.system.error)
    if (!error) return null

    return <div className="bg-red-300 p-4 naoko tracking-widest">
        Une erreur s'est produite ! N'hésitez pas à nous contacter si cela persiste.
    </div>
}