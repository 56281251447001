import Link from "next/link"
import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ExternalLink } from "./basics"
import { Dropdown } from "react-dropdown-now"
import "react-dropdown-now/style.css"
import { useRouter } from "next/router"
import { useTranslations } from "next-intl"
import classNames from "classnames"
import { Logo } from "./Logo"
import { RemoveScroll } from "react-remove-scroll"
import styles from "./SiteHeader.module.css"
import { LINKS } from "../lib/constants"
import { AddedToCart } from "./AddedToCart"
import { CSSTransition } from "react-transition-group"
import { usePreferences } from "../contexts/PreferencesContext"
import { cx } from "../lib/common"

const LogoLink = () => (
  <Link href="/">
    <Logo />
  </Link>
)

const CartLink = ({ itemsCount, onClick }) => {
  const calloutRef = useRef()
  const callout = useSelector((state) => state.system.callout)
  return (
    <Link href="/panier" onClick={onClick}>
      <div className={styles.cart}>
        <div className="relative p-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="30"
            viewBox="0 0 11.213 13.371"
          >
            <g transform="translate(0.5 0.5)">
              <path
                d="M2817.041,332.6v-1.626c0-1.425-1.438-2.575-3.22-2.575s-3.22,1.149-3.22,2.575v1.918"
                transform="translate(-2808.604 -328.4)"
                fill="none"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="0.8"
              />
              <path
                d="M2805.8,336.4h10.213v6.05c0,1.021-1.031,1.815-2.357,1.815h-5.5c-1.325,0-2.357-.794-2.357-1.815Z"
                transform="translate(-2805.8 -331.894)"
                fill="none"
                stroke="#000"
                strokeMiterlimit="10"
                strokeWidth="0.8"
              />
            </g>
          </svg>
          {
            itemsCount && itemsCount > 0 ? (
              <div className="absolute top-[16px] left-0 w-full text-center naoko text-base font-light">
                {itemsCount}
              </div>
            ) : null // DON'T USE && here! it will display "0" in the UI, see https://medium.com/geekculture/stop-using-for-conditional-rendering-in-react-a0f7b96200f8
          }
        </div>
        <CSSTransition
          in={callout !== null}
          nodeRef={calloutRef}
          classNames={{
            enter: styles["callout-wrapper-enter"],
            enterActive: styles["callout-wrapper-enter-active"],
            exit: styles["callout-wrapper-exit"],
            exitActive: styles["callout-wrapper-exit-active"],
          }}
          timeout={300}
          mountOnEnter
          unmountOnExit
        >
          <div
            ref={calloutRef}
            className="absolute top-[50px] right-[-64px] bg-white drop-shadow-xl z-20 w-[480px] p-8 text-left origin-[416px_top]"
          >
            <AddedToCart item={callout} />
          </div>
        </CSSTransition>
      </div>
    </Link>
  )
}

const Language = () => {
  const { locale, setLocale, locales } = usePreferences()
  return (
    <div className="cursor-pointer">
      <Dropdown
        options={locales}
        value={locale}
        className="!border-none !w-12 !p-0 -mr-[2px]"
        arrowClosed={<span></span>}
        arrowOpen={<span></span>}
        onSelect={(o) => setLocale(o.value)}
      />
    </div>
  )
}

const MobileLanguage = () => {
  const { locale, setLocale, locales } = usePreferences()
  return (
    <div className="grow basis-0 flex justify-center gap-6">
      {locales.map(({ value }) => (
        <button
          key={value}
          className={cx(locale === value && "font-medium")}
          onClick={() => setLocale(value)}
        >
          {value.toUpperCase()}
        </button>
      ))}
    </div>
  )
}

const Burger = ({ color = "black", onClick, mmo }) => {
  const line0 = "transform  translate-y-[4px] rotate-45    origin-center"
  return (
    <button onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="30"
        viewBox="0 0 12 12"
        className={classNames(styles.burger, { [styles.active]: mmo })}
      >
        <line
          x1="2"
          x2="10"
          y1="2"
          y2="2"
          stroke={color}
          strokeWidth="0.8"
          strokeLinecap="round"
        />
        <line
          x1="2"
          x2="10"
          y1="6"
          y2="6"
          stroke={color}
          strokeWidth="0.8"
          strokeLinecap="round"
        />
        <line
          x1="2"
          x2="10"
          y1="10"
          y2="10"
          stroke={color}
          strokeWidth="0.8"
          strokeLinecap="round"
        />
      </svg>
    </button>
  )
}

const Links = () => {
  const t = useTranslations("layout")
  return (
    <>
      <Link href="/shop">Shop</Link>
      <Link href="/fabrication">{t("fabrication")}</Link>
      <Link href="/a-propos">{t("engagements")}</Link>
      <Link href="/adresses">{t("adresses")}</Link>
      <ExternalLink href={LINKS.carnet}>{t("carnet")}</ExternalLink>
    </>
  )
}

const MobileMenu = ({ mmo, setMmo }) => {
  const t = useTranslations("layout")
  const opened =
    "opacity-100 [transition:transform_300ms_ease-out,opacity_400ms_ease-out]"
  const closed =
    "opacity-0 translate-y-[-120%] [transition:transform_300ms_300ms_ease-out,opacity_300ms_ease-out]"

  return (
    <div
      className={`fixed inset-0 top-[70px] bg-white z-[1] ${
        mmo ? opened : closed
      } `}
      onClick={() => setMmo(false)}
    >
      <RemoveScroll enabled={mmo} forwardProps>
        <div className=" h-full p-8 flex flex-col justify-between">
          <div className="flex flex-col gap-8 naoko uppercase font-normal">
            <Links />
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <Link href="/faq">FAQ</Link>
            </div>
            <div>
              <Link href="/retour">Retours</Link>
            </div>
            <div className="flex w-full">
              <MobileLanguage />
              <div>–</div>
              <div className="grow basis-0 flex justify-center gap-6">
                <div className="font-medium">€</div>
                <div>US$</div>
              </div>
            </div>
          </div>
        </div>
      </RemoveScroll>
    </div>
  )
}

export default function Header({ fullscreen }) {
  // Jul 2023 moved mmv to global state to hide banner when opened
  //const [mmo, setMmo] = useState(false)
  const dispatch = useDispatch()
  const mmo = useSelector((state) => state.system.mmo)
  const setMmo = (value) => dispatch({ type: "SET_MMO", payload: value })

  const cart = useSelector((state) => state.cart)
  const itemsCount = cart && cart.items && cart.items.length
  const t = useTranslations("layout")

  const toggleMenu = () => {
    // edge case where viewport is slightly scrolled when menu is opened
    // (menu is opened only on mobile so the media query is over-engineering)
    if (
      fullscreen &&
      typeof window !== "undefined" &&
      window.matchMedia("(max-width: 1024px)").matches
    ) {
      window.scrollTo(0, 0)
    }
    setMmo(!mmo)
  }
  const closeMenu = () => setMmo(false)
  const dark = fullscreen && !mmo // mobile only

  return (
    <>
      {" "}
      {/* don't enclose in div, its height will break sticky on mobile header */}
      <MobileMenu mmo={mmo} setMmo={setMmo} />
      <header
        className={classNames(styles.header, {
          [styles.fullscreen]: fullscreen,
          [styles.mmo]: mmo,
        })}
        style={{
          // applies to mobile only!
          "--mobile-color": dark ? "white" : "black",
          "--bg-color": dark ? "transparent" : "white",
        }}
      >
        <div className="lg:hidden">
          <Burger
            color={dark ? "white" : "black"}
            onClick={toggleMenu}
            mmo={mmo}
          />
        </div>
        <div className="lg:flex-1">
          <div className="lg:w-[200px] w-[150px]">
            <LogoLink />
          </div>
        </div>
        <nav className="lg:flex hidden gap-8 naoko font-light text-lg uppercase">
          <Links />
        </nav>
        <div className="lg:flex-1 flex justify-end items-center">
          <CartLink itemsCount={itemsCount} onClick={closeMenu} />
          <div className="lg:block hidden">
            <Language />
          </div>
        </div>
      </header>
    </>
  )
}
