import Router from "next/router" // for locale -- only use client side!
import { setError } from "./actions"
import { store } from "./store" // for errors only

export const isProd = process.env.NODE_ENV === "production"
const forceLocal = false

const apiUrl = (isProd && ! forceLocal) 
    ? 'https://bmj.leb.run/mmj2' 
    //: 'https://mmjback.loca.lt/mmj2'
    : 'http://localhost:3450/mmj2'

export const imgsrc = src => `https://bmj.leb.run/catalog/${src}`
export const aspect = pic => `${pic.dim[0]} / ${pic.dim[1]}`
export const test = typeof window === "object" && window.localStorage.getItem("mmjtest") === "true"
export const stripeKey = test ? "pk_test_9dLZUxQb8LiCGE09kwp3bv6s" : "pk_live_APwapE1cEQEYaWh3E2iUWhA6"
export const paypalKey = test ? "AQU9e2gtYK3axI4WpPDSljf-dd7g1RHMa5cxvIF6Js8WRFbaNJ5HtkK9MBeAOsioUMXe1WZAxSAqF8FL" : "ATwTdDDmvQVLIe1bv2EnRO29fUu7WLIXRyhYmzckDpGG0UhP-SSmjaVsHFOWt0dtzEnMt32jboCYALWN"

const trackingUid = () => (
    "100-100".replace(/[01]/g, c =>
        (((c ^ (crypto.getRandomValues(new Uint8Array(1))[0])) & 15) >> (c / 4)).toString(16)
    )
)

const readCookie = name => (
    typeof window === "object" && window.document.cookie.split("; ").find(r => r.startsWith(name))?.split("=")[1]
)

// we'll read it from localStorage once
let uid
if (typeof window === "object") {
    uid = window.localStorage.getItem("uid")
    if (uid === null) {
        uid = trackingUid()
        window.localStorage.setItem("uid", uid)
    }
} else {
    uid = "000-000" // SSR
}
export const getUid = () => uid

// getState is optional, passed when api is called from redux thunks
// in order to display errors
// it's not passed when called from Nextjs SSR functions
export const api = async (action, params = {} , options = {}) => {
    const { alertOnError = true } = options
    const path = typeof window === "object" && window.location.pathname
    const locale = typeof window === "object" && Router.locale
    const buildId = process.env.BUILD_ID
    const fb = { fbp: readCookie('_fbp'), fbc: readCookie('_fbc')}
    const data = { uid, action, path, locale, test, buildId, fb, ...params }
    isProd || console.log("API", data)
    
    try {
        const res = await fetch(apiUrl, {
            method: "POST",
            body: JSON.stringify(data),
        })
        if (res.status !== 200) {
            const text = await res.text()
            throw(new Error(`Status ${res.status}: ${text}`))
        }
        return await res.json()
    } catch (error) {
        // when the server is not reachable at all, we get a TypeError from fetch,
        // with message: "Failed to fetch"
        console.log("API error", error)
        if (alertOnError && store) {
            store.dispatch(setError(true))
            setTimeout(() => store.dispatch(setError(null)), 3000)
        }
        return Promise.reject()
    }
}

export const isObject = item =>
    (typeof item === "object" && !Array.isArray(item) && item !== null)

export const uuid = () => (
    ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        //    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        (((c ^ (crypto.getRandomValues(new Uint8Array(1))[0])) & 15) >> (c / 4)).toString(16)
    )
)

// return trimmed string if successful
export const validEmail = (email) => {
    if (!email) return false
    const trimmed = email.trim()
    return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(trimmed) && trimmed
}

// 11/2021 removed check after opening to other countries
export const validZip = (zip) => (
    // zip && /^\d{5}$/.test(zip)
    true
)
export const validString = (string) => (
    typeof string === "string" && string !== ""
)

export const cx = (...args) =>
  args
    .flat()
    .filter(x => 
      x !== null && x !== undefined && typeof x !== 'boolean'
    ).join(' ')