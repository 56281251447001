import { combineReducers } from 'redux'

// CART REDUCER
const initialCartState = {
    items: [],
    codes: [],
    shipping: null, // must be falsey or will badly render Shipping.js
    paymentOptions: {},
    fetched: false, // unset when fetched. used by payment and page to wait for the cart to load
    validShipping: false, // need to validate at least once the shipping form
}

const cartReducer = (state = initialCartState, { type, payload }) => {
  switch (type) {
    case "SET_CART":
      return {...payload.cart, fetched: true, validShipping: state.validShipping, }

    case "SET_SHIPPING":
    return {...payload.cart, fetched: true, validShipping: true, }
      
    default:
      return state
  }
}

// SYSTEM REDUCER
const initialSystemState = {
  error: null,
  callout: null,
  mmo: false,
  email: "",
}
const systemReducer = (state = initialSystemState, { type, payload }) => {
  switch (type) {
    case "SET_ERROR":
      return { ...state, error: payload, }

    case "SET_CALLOUT": // for cart popup
      return {...state, callout: payload } 

    case "SET_MMO": // for mobile menu open, to hide banner
      return {...state, mmo: payload }

    case "SET_SYSTEM_EMAIL": // to remember alerts
      return {...state, email: payload }

    default:
      return state
  }
}

// COMBINED REDUCERS
const reducers = {
  cart: cartReducer,
  system: systemReducer,
}

export default combineReducers(reducers)