export const FB_PIXEL_ID = "498082384446714"
const FB_CONTENT_TYPE = "product"

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
    if (!process.env.DISABLE_FACEBOOK
     && typeof fbq === 'function') {
        window.fbq('track', name, options)
    } else {
        console.log("Facebook pixel disabled in dev", name, options)
    }
}

export const pageview = () => {
    event('PageView')
}

// called from actions
export const addToCart = ({ id, name }) => {
    event("AddToCart", {
        FB_CONTENT_TYPE,
        content_ids: [id],
        content_name: name,
    })
}

// called from cart button
export const initiateCheckout = () => {
    event("InitiateCheckout")
}

// called from product page
export const viewContent = ({ id, name }) => {
    event("ViewContent", {
        FB_CONTENT_TYPE,
        content_ids: [id],
        content_name: name,
    })
}