// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

// may need to enable for ANALYZE
process.env.NEXT_PUBLIC_DISABLE_SENTRY !== 'true' && Sentry.init({
  dsn: SENTRY_DSN || 'https://1df4f79b55ed4d719f4fdd51ea2f0f43@o556913.ingest.sentry.io/6406974',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  tags: {buildId: process.env.BUILD_ID},
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
