import { useState, useEffect } from 'react'
import styles from './SuperButton.module.css'

// IMPROVE
// hover, check/cross transitions, pointer

// Boutons sur le site :

// Desktop
//   Home
//     "Shop" LIEN primary inline gros padding
//     "En savoir plus" LIEN secondary inline
//   Shop
//   Product
//     "Ajouter au panier" primary full width
//   Panier
//     "Commander" primary full width
//     "Code" secondary in flexbox
//     "Retirer" unstyled
//   Shipping
//     "Valider" primary full width
//   Paiement
//     "Payer" primary full width
//
// Mobile (boutons différents uniquement)
//   Home
//     "Shop" LIEN fullwidth avec marge

export const STATE = {
  LOADING: 'loading',
  DISABLED: 'disabled',
  SUCCESS: 'success',
  ERROR: 'error',
  NOTHING: ''
}

const Loading = () => (
  <svg className={styles['progress-circle']} viewBox='0 0 41 41'>
    <path d='M38,20.5 C38,30.1685093 30.1685093,38 20.5,38' />
  </svg>
)
const Success = () => (
  <svg className={styles.checkmark} viewBox='0 0 70 70'>
    <path d='m31.5,46.5l15.3,-23.2' />
    <path d='m31.5,46.5l-8.5,-7.1' />
  </svg>
)
const Error = () => (
  <svg className={styles.cross} viewBox='0 0 70 70'>
    <path d='m35,35l-9.3,-9.3' />
    <path d='m35,35l9.3,9.3' />
    <path d='m35,35l-9.3,9.3' />
    <path d='m35,35l9.3,-9.3' />
  </svg>
)

export const SuperButton = ({ children, onClick, disabled,
  autoResetOnSuccess, autoResetOnError = true, successDuration = 1200,
  errorDuration = 3000, className, style, type, form, buttonRef, ...delegated }) => {
  //const [clickHandler, finalHandler] = onClick || []
  const clickHandler = onClick
  const [state, setState] = useState()
  useEffect(() => setState(disabled ? STATE.DISABLED : STATE.NOTHING), [disabled])

  const _success = () => {
    setState(STATE.SUCCESS)
    setTimeout(() => {
      autoResetOnSuccess && setState(STATE.NOTHING)
    }, successDuration)
  }

  const _error = () => {
    setState(STATE.ERROR)
    setTimeout(() => {
      autoResetOnError && setState(STATE.NOTHING)
    }, errorDuration)
  }

  const _click = () => {
    if (state !== STATE.NOTHING) return
    let promise = clickHandler && clickHandler()
    if (promise && promise.then && promise.catch) {
      setState(STATE.LOADING)
      promise
        .then(_success)
        .catch(_error)
    } else if (promise) { // onClick returned true (not a promise)
      _success()
    } else {
      _error()
    }
  }

  return (
    <div className={`${className} ${styles.container} ${styles[state]}`} style={style} {...delegated}>
      <div className={styles.background}></div>
      <div className={styles.svgcontainer}>
        <Loading />
        <Success />
        <Error />
      </div>
      <button disabled={state === STATE.DISABLED} type={type} form={form} className={styles.button} onClick={_click} ref={buttonRef}>
        <span>{children}</span>
      </button>
    </div>

  )
}

export const SuperButtonTester = () => {
  const _click = () => new Promise((resolve, reject) => {
    setTimeout(resolve, 500)
  })
  const _fail = () => new Promise((resolve, reject) => {
    setTimeout(reject, 2000)
  })

  return <div>
    <div className="m-8">
      <SuperButton
        onClick={_click}
        className="naoko uppercase"
        style={{ "--height": "3rem" }}>
        Ajouter au panier
      </SuperButton>
    </div>
    <div className="m-8">
      <SuperButton onClick={_click} dontReset={true}>Click me!</SuperButton>
    </div>
    <div className="m-8">
      <SuperButton onClick={_fail}>Fail me!</SuperButton>
    </div>
    <div className="m-8">
      <SuperButton
        style={{
          "--bg-color": "white", "--color": "black",
          "--border": "1px solid black"
        }}
        onClick={_click}>Click me!</SuperButton>
    </div>
    <div className="m-8">
      <div className="flex">
        <input className="border border-gray-500 px-4 mr-[-1px] flex-1"
          type="text"
          placeholder="Code/Promo"
        />
        <SuperButton className="flex-shrink-0"
          style={{
            "--bg-color": "white", "--color": "black",
            "--border": "1px solid rgb(107 114 128)", "--height": "3rem"
          }}
          onClick={_click}>Click me!</SuperButton>
      </div>
    </div>
  </div>
}