import { imgsrc } from "../lib/common";
import Image from "next/image";
import { Fragment, useState } from "react";
import styles from "../styles/common.module.css";
import { useDispatch } from "react-redux";
import { useAppDispatch } from "../lib/store";
import { track } from "../lib/actions";
import { useRouter } from "next/router";
import { ImageProps } from "next/image";
import Link from "next/link";

export const Pic = ({
  pic,
  alt,
  priority,
  className,
  objectFit,
  layout,
}: {
  pic: { src: string; dim: number[] };
  alt?: string;
  priority?: any;
  className?: string;
  objectFit?: ImageProps["objectFit"];
  layout?: "fixed" | "responsive" | "fill" | "intrinsic";
}) => (
  <div style={{ position: "relative" }} className={className}>
    <Image
      src={imgsrc(pic.src)}
      layout={layout || "responsive"}
      width={pic.dim[0]} // with layout=responsive, means original width
      height={pic.dim[1]}
      alt={alt || ""}
      priority={priority}
      objectFit={objectFit}
    />
  </div>
);

export const Text = ({ text }) => (
  <>
    {(text || "")
      .trim()
      .split("\n")
      .map((item, i) => (
        <div key={i}>{item}&nbsp;</div>
      ))}
  </>
);

export const Raw = ({ html }) => (
  <>
    {(html || "").split("\n").map((item, i) => (
      <Fragment key={i}>
        <div dangerouslySetInnerHTML={{ __html: item }} />
        <br />
      </Fragment>
    ))}
  </>
);
export const RawWithP = ({ html }) => (
  <>
    {(html || "").split("\n").map((item, i) => (
      <p key={i} dangerouslySetInnerHTML={{ __html: item }} />
    ))}
  </>
)
export const RawWithDiv = ({ html }) => (
  <>
    {(html || "").split("\n").map((item, i) => (
      <div key={i} dangerouslySetInnerHTML={{ __html: item + "&nbsp;" }} />
    ))}
  </>
)

export const Tabs = ({ titles, children }) => {
  const [sel, setSel] = useState(0);
  return (
    <div>
      <div className={styles.tabtitles}>
        {titles.map((t, idx) => (
          <div
            key={t}
            onClick={() => setSel(idx)}
            className={idx === sel ? styles.tabselected : undefined}
          >
            {" "}
            {t}{" "}
          </div>
        ))}
      </div>
      {children.map((c, idx) => (
        <div
          key={idx}
          style={{ display: idx === sel ? "block" : "none" }}
          className={styles.tabcontent}
        >
          {c}
        </div>
      ))}
    </div>
  );
};

export const TextInput = ({
  name,
  label,
  subLabel,
  placeholder,
  value,
  onChange,
  pattern,
  type,
  track: _track = true,
  error,
  className,
}) => {
  const dispatch = useDispatch();
  return (
    <div className={className || "py-2"}>
      <label className={styles.label} htmlFor={name}>
        {label}
      </label>
      <input
        className="w-full p-2 font-sans tracking-widest border border-slate-400"
        type={type || "text"}
        spellCheck={false}
        name={name}
        value={value || ""}
        placeholder={placeholder}
        pattern={pattern}
        onChange={(e) => onChange(e.target.value)}
        onBlur={(e) =>
          _track &&
          dispatch(
            track({
              event: "fill",
              field: name,
              value: e.target.value,
            })
          )
        }
      />
      {error && (
        <div className={styles.error}>
          <span role="img" aria-label="sad">
            😰
          </span>{" "}
          {error}
        </div>
      )}
      {!error && subLabel && <div className={styles.sublabel}>{subLabel}</div>}
    </div>
  );
};

export const Select = ({
  children,
  name,
  label,
  placeholder,
  subLabel,
  value,
  onChange,
  error,
  style,
  className,
}) => {
  return (
    <div className={className || "py-2"}>
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      <select
        className="w-full p-2 font-sans tracking-widest border border-slate-400"
        name={name}
        value={value || ""}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      >
        {children}
      </select>
      {error && (
        <div style={{ fontSize: "0.9em", color: "red" }}>
          <span role="img" aria-label="sad">
            😰
          </span>{" "}
          {error}
        </div>
      )}
      {!error && subLabel && (
        <div style={{ fontSize: "0.9em" }}>{subLabel}</div>
      )}
    </div>
  );
};

const buttonClass = (type) => {
  switch (type) {
    case "primary":
      return "tracking-[0.2em] text-sm font-[500] bg-black text-white naoko uppercase p-4 text-center";
    case "secondary":
      return "tracking-[0.2em] text-sm font-[500] border border-black naoko uppercase p-3 text-center"; // smaller padding than primary
    case "link":
      return "underline";
    default:
      return "";
  }
};

export const ExternalLink = ({
  href,
  type,
  className,
  children,
  ...delegated
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  return (
    <a
      href={href} // for display
      className={`${buttonClass(type)} ${className}`}
      onClick={() => {
        dispatch(track({ event: "nav-external", href })).then(() =>
          router.push(href)
        );
      }}
      {...delegated}
    >
      {children}
    </a>
  );
};

// WARNING use for external links only
export const ButtonLink = ({
  href,
  type,
  className,
  children,
  ...delegated
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  return (
    <a
      href={href} // for display
      className={`${buttonClass(type)} ${className}`}
      onClick={() => {
        dispatch(track({ event: "nav-internal", href }));
        router.push(href);
      }}
      {...delegated}
    >
      {children}
    </a>
  );
};

export const withLink = (Component) => {
  const Linked = (props) => {
    // need to name the const for Nextjs
    if (props.href && props.href !== "") {
      return (
        <Link href={props.href}>
          <Component {...props} />
        </Link>
      );
    }
    return <Component {...props} />;
  };
  return Linked;
};

export const email = "info@mariejordane.com";
export const EmailLink = () => (
  <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
    {email}
  </a>
);
export const phone = "07.80.97.72.25";
