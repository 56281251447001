import  { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { imgsrc } from "../lib/common"
import Image from "next/image"
import { PrimaryButton, SecondaryButton } from "./PrimaryButton"
import { startCheckout } from "../lib/actions"
import { useDispatch } from "react-redux"

export const AddedToCart = ({ item, onClose }) => {
    console.log("item", item)
    const dispatch = useDispatch()
    const router = useRouter()
    const [added, setAdded] = useState(null)
    useEffect(() => {item && setAdded(item)}, [item])
    if (!added) return null
    const { product, size, price } = added
    const { name, baseline, pics } = product

    return <div className="flex flex-col gap-4 sm:gap-9 pt-1">
        <div>Produit ajouté</div>
        <div className="flex gap-4">
            <div className="w-[20%] leading-[0]">
                <Image
                    src={imgsrc(pics[0].src)}
                    width={pics[0].dim[0]}
                    height={pics[0].dim[1]}
                />
            </div>
            <div className="flex flex-col gap-2 pt-0">
                <div className="naoko text-xl font-normal">{name}</div>
                <div className="flex">
                    <div className="naoko text-lg">{size !== "_" ? `Taille ${size}`: ""}</div>
                    {/* <div className="naoko text-lg font-normal">{price}€</div> */}
                </div>
                <div className="italic">Livraison gratuite à partir de 200€
                    <br />Retours offerts
                </div>
            </div>
        </div>
        <div className="sm:hidden">
            <SecondaryButton 
                style={{'--height': '3.25rem'}} 
                onClick={onClose}>
                Continuer mes achats
            </SecondaryButton>
        </div>
        <div className="">
            <PrimaryButton 
                className="!block"
                onClick={() => {
                    dispatch(startCheckout())
                    router.push("/panier")
                    return true // important for SuperButton
                }}>
                Commander
            </PrimaryButton>
        </div>
    </div>
}