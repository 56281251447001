// Strategy for locale
// - If the route has explicit locale, never change
// - If the route has no explicit locale (does not start with /fr/ or /en/) AND no localStorage is set, check the browser's locale. If it's not French, change to English (will set new URL). Don't set localStorage.
// - If the user manually changes locale (in the menu), change AND set localStorage.


import React, { useContext, useEffect } from "react"
import { useRouter } from "next/router"
import { LOCALES } from "../lib/constants"

const PreferencesContext = React.createContext(null)

export const PreferencesProvider = ({ localeUrls, children }) => {
  const router = useRouter()
  const { locale, pathname, } = router

  const setLocale = (newLocale, setInStorage = true) => {
    if (locale === newLocale) return
    const newUrl = (localeUrls && localeUrls[newLocale]) || pathname // and query???
    router.push(newUrl, newUrl, { locale: newLocale })
    if (setInStorage && typeof window !== "undefined") {
      window.localStorage.setItem("mmj_locale", newLocale)
    }
  }

  useEffect(() => { // runs at inial page load only
    if (localeNotExplicitInURL(window.location.pathname)) {
      const fromStorage = localeFromStorage()
      if (fromStorage) {
        setLocale(fromStorage, false)
      } else {
        if(!acceptsFrench()) {
          setLocale("en", false)
        }
      }
    }
  }, [])

  const value = { locale, setLocale, locales: LOCALES }

  return (
    <PreferencesContext.Provider value={value}>
      {children}
    </PreferencesContext.Provider>
  )
}

export const usePreferences = () => {
  return useContext(PreferencesContext)
}

const localeNotExplicitInURL = pathname => (
  !pathname.match(/^\/(fr|en)(\/|$)/)
)

const localeFromStorage = () => {
  if (typeof window !== "undefined") {
    const s = window.localStorage.getItem("mmj_locale")
    if (["fr", "en"].includes(s)) return s
  }
  return false
}

const acceptsFrench = () => {
  try {
    return navigator.languages.reduce((acc, x) => (
      acc || (x.match(/^fr/))), false
    )
  } catch (error) {
    return true // by default, don't change locale
  }
}
