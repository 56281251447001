import { Provider, useDispatch } from 'react-redux'
import { useStore } from '../lib/store'
import '../styles/globals.css'
import Layout from '../components/layout'
import 'react-responsive-modal/styles.css'
import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'
import { submitGsc, track } from '../lib/actions'
import * as gtag from '../lib/gtag'
import * as fbq from '../lib/fpixel'
import { NextIntlProvider } from 'next-intl'
import { affilaeTrackingStript } from '../lib/affilae'
import IntlPolyfills from '../lib/polyfills'
import { getUid, test } from '../lib/common'
import posthog from 'posthog-js'
import { PreferencesProvider } from '../contexts/PreferencesContext'

// 12 nov 2022, Interesting behavior
// PayPalButtons uses a callback function that uses `cash`
// The first time it is rendered, `cash` is undefined.
// When `cash` becomes defined, weirdly PayPalButtons is NOT rerendered, so it keeps the wrong callback
// Fixed by rendering the buttons only when `cash` is defined... but that's hacky!

export default function MyApp({ Component, pageProps }) {
  const store = useStore(pageProps.initialReduxState)
  const router = useRouter()

  // reload if not latest version
  // const buildId = useBuildId()
  // const previousBuildId = usePrevious(buildId)
  // useEffect(() => {
  //   console.log("effect", buildId, previousBuildId)
  //   if (buildId && previousBuildId && (buildId !== previousBuildId)) {
  //     console.log("reload")
  //     router.reload()
  //   }
  // }, [buildId, previousBuildId]) 

  // track navigation for analytics etc.
  useEffect(() => {
    // This pageview only triggers the first time (it's important for Pixel to have real information)
    fbq.pageview()

    const handleRouteChange = (url) => {
      store.dispatch(track({ event: 'nav', location: url }))
      gtag.pageview(url)
      fbq.pageview()
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  useEffect(() => {
    if (typeof window === "object") {
      const referrer = window.document.referrer
      import('ua-parser-js').then(ua => {
        const { browser, os, device } = ua.default()
        const screen = { width: window.innerWidth, height: window.innerHeight }
        store.dispatch(track({ event: 'land', referrer, browser, os, device, screen }))
      })
    }
  }, [])

  // Posthog product analytics
  useEffect(() => {
    if (test) return
    posthog.init('phc_fBlSCq8KefZUiy2ZpEcpfc4AJbXPW7LkCW3FPTExsDW', {
      api_host: 'https://app.posthog.com',
      loaded: ph => ph.identify(getUid()),
    })
  }, [])

  return (
    <>
      {/* Global Site Code Pixel - Facebook Pixel 
            Followed Nextjs example (updated Sep 2021), they moved the script
            from document to app. */}
      {!process.env.NEXT_PUBLIC_DISABLE_FACEBOOK && <Script
        id="fbpixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            setTimeout(() => {
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${fbq.FB_PIXEL_ID});
              }, 5000);
          `,
        }}
      />}
      <Script
        id="affilae"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: affilaeTrackingStript
        }}
      />
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <Provider store={store}>
        <PreferencesProvider localeUrls={pageProps.localeUrls}>
          {/* Bypass layout for 404 page or any page without messages props */}
          {pageProps.messages ? <NextIntlProvider messages={pageProps.messages}>
            <IntlPolyfills />
            <Layout {...pageProps}>
              <Component {...pageProps} />
              {/* Moved GSC script here in order to use Next's Script component, which provides onLoad and cannot be used inside Head or Html */}
              {!process.env.NEXT_PUBLIC_DISABLE_GETSITECONTROL && <Script
                id="getsitecontrol"
                src={`https://l.getsitecontrol.com/p4r5xlk7.js`}
                onLoad={() => window.gsc('onSubmit', (widgetId, data) => {
                  // data looks like {name: 'Alex', email: 'alexgsc@lxbrun.com'}
                  if (true) { // (widgetId === 286429) { // Julie may change it!
                    store.dispatch(submitGsc(data))
                  }
                })} />}
              <Script
                id="chatwoot"
                src="https://app.chatwoot.com/packs/js/sdk.js"
                strategy="afterInteractive"
                onLoad={() => {
                  window.chatwootSDK.run({
                    websiteToken: 'Yv2izZEgLveUvax4fKbUaTag',
                    baseUrl: "https://app.chatwoot.com",
                  })
                }} />
            </Layout>
          </NextIntlProvider> : <Component {...pageProps} />}
        </PreferencesProvider>
      </Provider>
    </>
  )
}
