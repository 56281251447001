import Header from "./SiteHeader"
import { useEffect, useState } from 'react'
import { getCart } from '../lib/actions'
import { useDispatch } from 'react-redux'
import Head from "next/head"
import { useTranslations } from "next-intl"
import { imgsrc, test } from "../lib/common"
import { Banner, ErrorBanner } from '../components/Banner'

const TestButton = () => {
  const [t, setT] = useState(false)
  useEffect(() => {test && setT(true)}, [])
  const stop = () => {
    window.localStorage.setItem("mmjtest", "false")
    window.location = "/"
  }
  return t && <div className="fixed bottom-4 left-4 bg-green-400 p-2 flex items-baseline gap-2">
    <span className="font-bold">TEST</span>
    <button style={{display: "inline-block"}} onClick={stop}>x</button>
  </div>
}

export default function Layout({ children, content, banner, fullscreen }) {
  const { meta } = content
  const { home_pics } = meta

  // load the cart once at first load/refresh
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCart())
  }, [])
  const t = useTranslations('meta')

  return (
    <>
      {/* default Head, can be overriden in children components */}
      <Head>
        <title key="title">{t('titre')}</title>
        <meta key="metadesc" name="description" content={t('description')} />
        <meta key="ogtype" property="og:type" content="website" />
        <meta key="ogurl" property="og:url" content={`https://mariannebymariejordane.com`} />
        <meta key="ogtitle" property="og:title" content={t('titre')} />
        <meta key="ogdesc" property="og:description" content={t('description')} />
        <meta key="ogimage" property="og:image" content={imgsrc(home_pics[0].src)} />
      </Head>
      <ErrorBanner />
      <Banner banner={banner} fullscreen={fullscreen} />
      <div className="max-w-[1200px] mx-auto">
        <Header fullscreen={fullscreen} />
        <main className="isolate">{children}</main>
        <TestButton />
      </div>
    </>
  )
}