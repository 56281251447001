const BREAKPOINTS = {
  laptopMax: 81.25,
  tabletMax: 59.375,
  mobileMax: 37.5,
}

export const LOCALES = [
  { value: "fr", label: "🇫🇷" },
  { value: "en", label: "🇺🇸" },
]

// use ISO3166 Alpha-2 https://www.iban.com/country-codes
// will be reordered by locale
// the second item is the shipping options
export const COUNTRIES = {
  FR: ["normal", "express"],
  DE: ["europe"],
  AT: ["europe"],
  BE: ["europe"],
  ES: ["europe"],
  IT: ["europe"],
  LU: ["europe"],
  NL: ["europe"],
  US: ["monde"],
  PT: ["europe"],
  DK: ["europe"],
  SE: ["europe"],
  SG: ["monde"],
  CA: ["monde"],
}
export const DEFAULT_COUNTRY = {
  // by locale
  fr: "FR",
  en: "US",
}

export const QUERIES = {
  laptopAndDown: `(max-width: ${BREAKPOINTS.laptopMax}rem)`,
  tabletAndDown: `(max-width: ${BREAKPOINTS.tabletMax}rem)`,
  mobileAndDown: `(max-width: ${BREAKPOINTS.mobileMax}rem)`,
}

export const LINKS = {
  engagements: "https://carnet.mariannebymariejordane.com/nos-engagements",
  adresses: "https://carnet.mariannebymariejordane.com/boutique",
  carnet: "https://carnet.mariannebymariejordane.com/blog",
  rdv: "https://calendly.com/rdv-mmj/rendez-vous-en-boutique",
}

export const SIZE_GUIDE = [
  ["FRANCE", "32", "34", "36", "38", "40", "42", "44"],
  ["", "XXS", "XS", "S", "M", "L", "XL", "XXL"],
  [
    "Tour de poitrine (cm)",
    "72-76",
    "77-81",
    "82-86",
    "87-91",
    "92-96",
    "98-101",
    "102-106",
  ],
  [
    "Tour de taille (cm)",
    "56-60",
    "61-65",
    "66-70",
    "71-75",
    "76-80",
    "81-85",
    "86-90",
  ],
  [
    "Tour de bassin (cm)",
    "81-85",
    "86-90",
    "91-95",
    "96-100",
    "101-105",
    "106-110",
    "111-114",
  ],
]
